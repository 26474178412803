/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'gatsby';
import Figure from './figure';
import BlockContent from '@sanity/block-content-to-react';
import * as typographyStyles from '../styles/typography.module.css';
import * as serializerStyles from './serializers.module.css';
import softSearch from '../utils/linkHelper';
import { ConditionalWrapper } from '../utils/tools';
import { colorsToClasses } from './sections/section';
import { cn } from '../lib/helpers';
import clientConfig from '../../client-config';

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node;

  switch (style) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
      return React.createElement(
        style,
        { className: typographyStyles['responsiveTitle' + style.replace(/[^\d]/g, '')] },
        props.children
      );

    case 'blockquote':
      return <blockquote className={typographyStyles.blockQuote}>{props.children}</blockquote>;

    case 'normal':
      return <p className={typographyStyles.paragraph}>{props.children}</p>;

    case 'micro':
      return <div className={typographyStyles.micro}>{props.children}</div>;

    case 'large':
      return <h2 className={typographyStyles.large}>{props.children}</h2>;

    case 'small':
      return <p className={typographyStyles.small}>{props.children}</p>;

    case 'huge':
      return <h2 className={typographyStyles.responsiveTitleHuge}>{props.children}</h2>;

    default:
      return BlockContent.defaultSerializers.types.block(props);
  }
};

const isExternalHrefPattern = (href) => {
  return (
    href.indexOf('http:') === 0 ||
    href.indexOf('https:') === 0 ||
    href.indexOf('mailto:') === 0 ||
    href.indexOf('tel:') === 0
  );
};

const serializers = (setGatedModalOpen, setGatedModalLink) => ({
  types: {
    // Text styles
    block: BlockRenderer,

    // Images
    figure: Figure,

    // custom HTML
    embedHTML(props) {
      // hack for vids that where added using embed html option before embedvideo existed
      if (props.node.html?.includes('youtube') || props.node.html?.includes('vimeo')) {
        return (
          <div className={serializerStyles.videoContainer}>
            <div dangerouslySetInnerHTML={{ __html: props.node.html }} />
          </div>
        );
      }
      return <div dangerouslySetInnerHTML={{ __html: props.node.html }} />;
    },

    // custom Video Embed use responsive wrapper
    embedVideo(props) {
      return (
        <div className={serializerStyles.videoContainer}>
          <div dangerouslySetInnerHTML={{ __html: props.node.html }} />
        </div>
      );
    },
  },
  marks: {
    // normal external links
    link: ({ mark: { href, style, alignment }, children }) => {
      // console.log('external link: ', href);
      // console.log('style: ', style);
      // console.log('alignment: ', alignment);
      const isButton = style === 'button';
      const isSecondaryButton = style === 'secondaryButton';
      const linkClasses = serializerStyles[style] || serializerStyles.link;
      const alignmentStyle = serializerStyles[alignment] || '';

      let isInternalLink = false;
      if (!href.includes('mailto:') && !href.includes('tel:')) {
        let hrefSplit = href?.split('/');
        const result = softSearch(clientConfig.gatsby.siteBaseUrl, hrefSplit);
        if (result) {
          isInternalLink = true;
          hrefSplit.splice(0, result[1] + 1);
          href = `/${hrefSplit.join('/')}`;
        }
      }

      if (href && (isInternalLink || !isExternalHrefPattern(href))) {
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <Link to={href} className={linkClasses}>
              {children}
            </Link>
          </ConditionalWrapper>
        );
      } else {
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <a
              href={href}
              className={linkClasses}
              target={href && isExternalHrefPattern(href) ? '_blank' : ''}
              rel={href && isExternalHrefPattern(href) ? 'noopener' : ''}
            >
              {children}
            </a>
          </ConditionalWrapper>
        );
      }
    },

    gatedLink: ({ mark: { href, style, alignment, file }, children }) => {
      // console.log('gatedLink href: ', href);
      // console.log('alignment: ', alignment);
      const isButton = style === 'button';
      const isSecondaryButton = style === 'secondaryButton';
      const linkClasses = cn('contentButton', serializerStyles[style] || serializerStyles.link);
      const alignmentStyle = serializerStyles[alignment] || '';

      let hrefSplit = href?.split('/');
      const result = softSearch(clientConfig.gatsby.siteBaseUrl, hrefSplit);

      let isInternalLink = false;
      if (result) {
        isInternalLink = true;
        hrefSplit.splice(0, result[1] + 1);
        href = `/${hrefSplit.join('/')}`;
      }

      if (file) {
        href = file.asset.url;
        isInternalLink = false;
      }

      if (href && (isInternalLink || !isExternalHrefPattern(href))) {
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <a
              onClick={(e) => {
                setGatedModalOpen(true);
                setGatedModalLink(href);
                e.preventDefault();
              }}
              href=""
              className={linkClasses}
            >
              {children}
            </a>
          </ConditionalWrapper>
        );
      } else {
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <a
              onClick={(e) => {
                setGatedModalOpen(true);
                setGatedModalLink(href);
                e.preventDefault();
              }}
              href=""
              className={linkClasses}
            >
              {children}
            </a>
          </ConditionalWrapper>
        );
      }
    },

    // centered text content
    textCenter: ({ children }) => {
      return <span style={{ textAlign: 'center', display: 'block' }}>{children}</span>;
    },

    textColor: ({ mark, children }) => {
      // console.log('textColor: ', mark.color.value);
      const color = mark.color?.value;
      // prefer classes if they exist
      const colorClass = colorsToClasses[color] || color;
      return <span className={serializerStyles[colorClass]}>{children}</span>;
    },

    // internal links
    internalLink: ({ mark: { reference: ref, style, alignment }, children }) => {
      // console.log('alignment: ', alignment);
      // console.log('style: ', style);
      const isButton = style === 'button';
      const isSecondaryButton = style === 'secondaryButton';
      let href = null;
      let title = '';
      const linkClasses = serializerStyles[style] || serializerStyles.link;
      const alignmentStyle = serializerStyles[alignment] || '';
      if (ref)
        switch (ref._type) {
          case 'page':
            href = '/' + ref.slug.current;
            title = ref.title;
            break;
        }
      if (href)
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <Link to={href} title={title} className={linkClasses}>
              {children}
            </Link>
          </ConditionalWrapper>
        );
      else
        return (
          <ConditionalWrapper
            condition={isButton || isSecondaryButton}
            wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
          >
            <span className={linkClasses}>{children}</span>
          </ConditionalWrapper>
        );
    },

    // print link
    printLink: ({ mark: { style, alignment }, children }) => {
      const isButton = style === 'button';
      const isSecondaryButton = style === 'secondaryButton';
      const alignmentStyle = serializerStyles[alignment] || '';

      return (
        <ConditionalWrapper
          condition={isButton || isSecondaryButton}
          wrapper={(children) => <span className={alignmentStyle}>{children}</span>}
        >
          <a
            href="#"
            onClick={() => {
              window.print();
              return false;
            }}
          >
            {children}
          </a>
        </ConditionalWrapper>
      );
    },
  },

  // block: BlockRenderer
});

export default serializers;
